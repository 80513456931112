'use client';

// imports
import { ReactNode } from 'react';

// lib files
import { useState } from 'react';

// svgs
import AddIcon from '/public/svg/add.svg';
import MinusIcon from '@/public/svg/minus.svg';

// styles
import styles from './PassportFAQItem.module.scss';
interface PassportFAQItemProps {
  question: string;
  answer: ReactNode[];
  key: number;
}
export default function PassportFAQItem(props: PassportFAQItemProps) {
  const {
    question,
    answer,
    key
  } = props;
  const [isOpen, setisOpen] = useState(false);
  const answerContent = answer.map((item, i) => {
    return <div key={i} className={styles.passport_faq_answer_item}>
        {item}
      </div>;
  });
  const handleToggleFAQItem = () => {
    setisOpen(previsOpen => {
      return !previsOpen;
    });
  };
  return <li className={styles.passport_faq_item} key={key} data-sentry-component="PassportFAQItem" data-sentry-source-file="PassportFAQItem.tsx">
      <div className={styles.passport_faq_question} onClick={() => handleToggleFAQItem()}>
        <h3>{question}</h3>
        <button aria-label={isOpen ? 'Close Answer' : 'Open Answer'}>
          {!isOpen && <AddIcon className={styles.passport_faq_toggle_icon} />}
          {isOpen && <MinusIcon className={styles.passport_faq_toggle_icon} />}
        </button>
      </div>
      {isOpen && <div className={styles.passport_faq_answer} onClick={() => handleToggleFAQItem()}>
          {answerContent}
        </div>}
    </li>;
}